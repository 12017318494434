import React, { useContext, useEffect, useState } from 'react';
import './css/LightSwitch.css'
import axios from 'axios';
import { AuthContext } from './AuthContext';

const LightSwitch = ({lightName, showProfileSelector, config, dayTime}) => {
  const [profiles, setProfiles] = useState({
    airvent_timer1: {
      start: config[0].airvent_start1,
      end: config[0].airvent_end1,
      start_name: 'airvent_start1',
      end_name: 'airvent_end1',
      status: config[0].airvent_timer1,
      status_name: 'airvent_timer1'
    },
    airvent_timer2: {
      start: config[0].airvent_start2,
      end: config[0].airvent_end2,
      status: config[0].airvent_timer2,
      start_name: 'airvent_start2',
      end_name: 'airvent_end2',
      status_name: 'airvent_timer2'
    },
    airvent_timer3: {
      start: config[0].airvent_start3,
      end: config[0].airvent_end3,
      status: config[0].airvent_timer3,
      start_name: 'airvent_start3',
      end_name: 'airvent_end3',
      status_name: 'airvent_timer3'
    },
    airout_timer1: {
      start: config[0].airout_start1,
      end: config[0].airout_end1,
      status: config[0].airout_timer1,
      start_name: 'airout_start1',
      end_name: 'airout_end1',
      status_name: 'airout_timer1'
    },
    airout_timer2: {
      start: config[0].airout_start2,
      end: config[0].airout_end2,
      status: config[0].airout_timer2,
      start_name: 'airout_start2',
      end_name: 'airout_end2',
      status_name: 'airout_timer2'
    },
    airout_timer3: {
      start: config[0].airout_start3,
      end: config[0].airout_end3,
      status: config[0].airout_timer3,
      start_name: 'airout_start3',
      end_name: 'airout_end3',
      status_name: 'airout_timer3'
    },
    mainlight_timer: {
      start: config[0].light_start,
      end: config[0].light_end,
      start_name: 'light_start',
      end_name: 'light_end'
    }
  });
  const [startTime, setStartTime] = useState(lightName === 'Hauptlicht'? config[0].light_start : lightName === 'Zweitlicht' ? config[0].seclight_start_time : lightName === 'Ventilator' ? config[0].airvent_start1 : config[0].airout_start1);
  const [endTime, setEndTime] = useState(lightName === 'Hauptlicht'? config[0].light_end : lightName === 'Zweitlicht' ? config[0].seclight_end_time : lightName === 'Ventilator' ? config[0].airvent_end1 : config[0].airout_end1);
  const [timeDiff, setTimeDiff] = useState('');
  const [lightDimmTime, setLightDimmTime] = useState(config[0].light_dimm_time);
  const [lightPower, setLightPower] = useState(config[0].light_power * 100)
  const [selectedProfile, setSelectedProfile] = useState('timer1');
  const [checkBox, setCheckBox] = useState(lightName === 'Ventilator' ? config[0].airvent_timer1 : config[0].airout_timer1)
  
  const {loggedIn} = useContext(AuthContext)
 
  const handleProfileChange = (event) => {
    console.log(event.target.value)
    setSelectedProfile(event.target.value);
    if (lightName === 'Abluft') {
      if (event.target.value === 'timer1') {
        const profileData = profiles['airout_timer1'];
        setStartTime(profileData.start);
        setEndTime(profileData.end);
        setCheckBox(profileData.status);
      }
      else if (event.target.value === 'timer2') {
        const profileData = profiles['airout_timer2'];
        setStartTime(profileData.start);
        setEndTime(profileData.end);
        setCheckBox(profileData.status);
      }
      else if (event.target.value === 'timer3') {
        const profileData = profiles['airout_timer3'];
        setStartTime(profileData.start);
        setCheckBox(profileData.status);
        setEndTime(profileData.end);
      }
    }
    else if (lightName === 'Ventilator'){
      if (event.target.value === 'timer1') {
        const profileData = profiles['airvent_timer1'];
        setStartTime(profileData.start);
        setCheckBox(profileData.status);
        setEndTime(profileData.end);
      }
      else if (event.target.value === 'timer2') {
        const profileData = profiles['airvent_timer2'];
        setStartTime(profileData.start);
        setCheckBox(profileData.status);
        setEndTime(profileData.end);
      }
      else if (event.target.value === 'timer3') {
        const profileData = profiles['airvent_timer3'];
        setStartTime(profileData.start);
        setCheckBox(profileData.status);
        setEndTime(profileData.end);
      }
    }
  }

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  const handleLightDimmTimeChange = (event) => {
    setLightDimmTime(event.target.value);
  };
  
  const handleLightPowerChange = (event) => {
    setLightPower(event.target.value);
  };
  

  const handleSave = () => {
    let formData = new FormData()
    let body = {}
    if (lightName === 'Abluft') {
      if (selectedProfile === 'timer1') {
        formData.append('airout_start1', startTime)
        formData.append('airout_end1', endTime)
        formData.append('airout_timer1', checkBox)
      }
      else if (selectedProfile === 'timer2') {
        formData.append('airout_start2', startTime)
        formData.append('airout_end2', endTime)
        formData.append('airout_timer2', checkBox)
      }
      else if (selectedProfile === 'timer3') {
        formData.append('airout_start3', startTime)
        formData.append('airout_timer3', checkBox)
        formData.append('airout_end3', endTime)
      }
    } else if (lightName === 'Ventilator') {
      if (selectedProfile === 'timer1') {
        formData.append('airvent_start1', startTime)
        formData.append('airvent_end1', endTime)
        formData.append('airvent_timer1', checkBox)
      }
      else if (selectedProfile === 'timer2') {
        formData.append('airvent_start2', startTime)
        formData.append('airvent_end2', endTime)
        formData.append('airvent_timer2', checkBox)
      }
      else if (selectedProfile === 'timer3') {
        formData.append('airvent_start3', startTime)
        formData.append('airvent_end3', endTime) 
        formData.append('airvent_timer3', checkBox)
      }
    } else if (lightName === 'Hauptlicht') {
      formData.append('light_start', startTime)
      formData.append('light_end', endTime)
      formData.append('light_power', lightPower/100)
      formData.append('light_dimm_time', lightDimmTime)
    } else if (lightName === 'Zweitlicht') {
      formData.append('seclight_start_time', startTime)
      formData.append('seclight_end_time', endTime)
    }
    
  
    
    axios.post(`https://restapi.gralla-tec.de/config`, formData)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleSwitch = () => {
    checkBox? setCheckBox(0) : setCheckBox(1);
  }

  const reset_settings = () => {
    setStartTime(config[0].light_start);
    setEndTime(config[0].light_end);
    setLightDimmTime(config[0].light_dimm_time);
    setLightPower(config[0].light_power * 100);
  };
  const calculateTimeDiff = () => {
    const start = new Date(`01/01/2000 ${startTime}`);
    const end = new Date(`01/01/2000 ${endTime}`);
    const diff = end.getTime() - start.getTime();
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if (minutes === 0) {
      setTimeDiff(`${hours} Stunden`);
    }
    else setTimeDiff(`${hours} Stunden ${minutes} Minuten`);
  };

  useEffect(() => {
    calculateTimeDiff();
  }, [startTime, endTime])

  return (
    <div className="light-switch-container">
      <div className="light-switch-heading-container">
        <h2 className="light-switch-heading">{lightName}</h2>
        {lightName === 'Hauptlicht' ? (
          <>

          </>
        ) : null}
      </div>
      <div className="light-switch-timeactive-container">
        <p className='light-switch-timeactive-label'>Laufzeit:</p>
        <p className='light-switch-timeactive-value'>{timeDiff}</p>
      </div>
      <div className="light-switch-inputs">
        <label htmlFor={`${lightName}-start-time`}>Einschaltzeit:</label>
        <input className="light-switch-time-input" type="time" id={`${lightName}-start-time`} name={`${lightName}-start-time`} value={startTime} onChange={handleStartTimeChange} />
        <label htmlFor={`${lightName}-end-time`}>Ausschaltzeit:</label>
        <input className="light-switch-time-input" type="time" id={`${lightName}-end-time`} name={`${lightName}-end-time`} value={endTime} onChange={handleEndTimeChange} />
        {lightName === 'Hauptlicht' ? (
          <>
            <label htmlFor={`${lightName}-dimm-time`}>Dimmzeit: {lightDimmTime} min</label>
            <input className="light-switch-time-input" type="range" id={`${lightName}-dimm-time`} name={`${lightName}-dimm-time`} min="0" max="60" value={lightDimmTime} onChange={handleLightDimmTimeChange} />
            <label htmlFor={`${lightName}-power`}>Lichtstärke: {lightPower}%</label>
            <input className="light-switch-time-input" type="range" id={`${lightName}-power`} name={`${lightName}-power`} min="0" max="100" value={lightPower} onChange={handleLightPowerChange} />
          </>
        ) : null}
        {showProfileSelector && (
          <>
            <div className="light-switch-profile-selector">
              <label htmlFor={`${lightName}-profile`}>Profil auswählen:</label>
              <select id={`${lightName}-profile`} name={`${lightName}-profile`} value={selectedProfile} onChange={handleProfileChange}>
                <option value="timer1">1.</option>
                <option value="timer2">2.</option>
                <option value="timer3">3.</option>
              </select>
            <button className={`light-switch-button-${checkBox}`} onClick={handleSwitch}>{checkBox? 'Aktiviert' : 'Deaktiviert'}</button>
            </div>
          </>
        )}
         { loggedIn && 
          <>
          <div className="light-switch-buttons-containers">
            <button className={`light-switch-button ${dayTime}btn`} onClick={handleSave}>Speichern</button>
            <button className={`light-switch-button ${dayTime}btn`} onClick={reset_settings}>Zurücksetzen</button>
           </div>
          </>
        }
      </div>
    </div>
  );

};

export default LightSwitch;
