import React, { useContext, useState } from "react";
import "./css/Settings.css";
import axios from "axios";
import { AuthContext } from "./AuthContext";

const Settings = ({ data, config }) => {

  const [modeAirOut, setModeAirOut] = useState(config[0].airout_set_cont === 1 ? 2 :  config[0].airout_set === 1 ? 1 : 0)
  const [modeMainLight, setModeMainLight] = useState(config[0].mainlight_set_cont === 1 ? 2 :  config[0].mainlight_set === 1 ? 1 : 0)
  const [modeSecLight, setModeSecLight] = useState(config[0].seclight_set_cont === 1 ? 2 :  config[0].seclight_set === 1 ? 1 : 0)
  const [modeAirVent, setModeAirVent] = useState(config[0].airvent_set_cont === 1 ? 2 :  config[0].airvent_set === 1 ? 1 : 0)
  const body = {}

  const {loggedIn} = useContext(AuthContext)
  const modeString = (mode) => {
    let result
    if (mode === 0) {
        result = 'AUS'
    }
    else if (mode === 1) {
        result = 'AUTO'
    }
    else if (mode === 2) {
        result = 'AN'
    }
    return result
  }

  const axiosSetConfig = (body) => {
    axios.post('https://restapi.gralla-tec.de/config', body)
        .then(response => console.log(response))
        .catch(error => console.log(error))
    console.log(body)
  }
  const handleSaveAirout = () => {
    if (!loggedIn) { 
      console.log('not authorized');
      return
    }
    let formData = new FormData()
    if (modeAirOut === 2) {
        setModeAirOut(0);
        formData.append('airout_set', '0')
        formData.append('airout_set_cont', '0')
        axiosSetConfig(formData)
    } else if (modeAirOut === 1) {
        setModeAirOut(2);
        formData.append('airout_set', '0')
        formData.append('airout_set_cont', '1')
        axiosSetConfig(formData)
    } else {
        setModeAirOut(1);
        formData.append('airout_set', '1')
        formData.append('airout_set_cont', '0')
        axiosSetConfig(formData)
    }
  }

  
  const handleSaveAirvent = () => {
    if (!loggedIn) { 
      console.log('not authorized');
      return
    }
    let formData = new FormData()
    if (modeAirVent === 2) {
        setModeAirVent(0)
        formData.append('airvent_set', '0')
        formData.append('airvent_set_cont', '0')
        axiosSetConfig(formData)
    }
    else if (modeAirVent === 1) {
        setModeAirVent(2)
        formData.append('airvent_set', '0')
        formData.append('airvent_set_cont', '1')
        axiosSetConfig(formData)
    }
    else {
        setModeAirVent(1)
        formData.append('airvent_set', '1')
        formData.append('airvent_set_cont', '0')
        axiosSetConfig(formData)
    }
  }
  const handleSaveMainlight = () => {
    if (!loggedIn) { 
      console.log('not authorized');
      return
    }
    let formData = new FormData()
    if (modeMainLight === 2) {
        setModeMainLight(0)
        formData.append('mainlight_set', '0')
        formData.append('mainlight_set_cont', '0')
        axiosSetConfig(formData)
    }
    else if (modeMainLight === 1) {
        setModeMainLight(2)
        formData.append('mainlight_set', '0')
        formData.append('mainlight_set_cont', '1')
        axiosSetConfig(formData)
    }
    else {
        setModeMainLight(1)
        formData.append('mainlight_set', '1')
        formData.append('mainlight_set_cont', '0')
        axiosSetConfig(formData)
    }
  }
  const handleSaveSeclight = () => {
    if (!loggedIn) { 
      console.log('not authorized');
      return
    }
    let formData = new FormData()
    if (modeSecLight === 2) {
        setModeSecLight(0)
        formData.append('seclight_set', '0')
        formData.append('seclight_set_cont', '0')
        axiosSetConfig(formData)
    }
    else if (modeSecLight === 1) {
        setModeSecLight(2)
        formData.append('seclight_set', '0')
        formData.append('seclight_set_cont', '1')
        axiosSetConfig(formData)
    }
    else {
        setModeSecLight(1)
        formData.append('seclight_set', '1')
        formData.append('seclight_set_cont', '0')
        axiosSetConfig(formData)
    }
  }

  
    return (
        <div className="settings-container">
          <div className={`setting ${modeString(modeMainLight)}`} onClick={handleSaveMainlight}>
            <div className="color-white">Hauptlicht</div>
            <div>{modeString(modeMainLight)}</div>
          </div>
          <div className={`setting ${modeString(modeSecLight)}`} onClick={handleSaveSeclight}>
            <div className="color-white">Zweitlicht</div>
            <div>{modeString(modeSecLight)}</div>
          </div>
          <div className={`setting ${modeString(modeAirVent)}`} onClick={handleSaveAirvent}>
            <div className="color-white">Ventilator</div>
            <div>{modeString(modeAirVent)}</div>
          </div>
          <div className={`setting ${modeString(modeAirOut)}`} onClick={handleSaveAirout}>
            <div className="color-white">Abluft</div>
            <div>{modeString(modeAirOut)}</div>
          </div>
        </div>
      );
};

export default Settings;

