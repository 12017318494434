import React, { createRef, useContext, useState } from 'react'
import './css/Tent.css'
import MeasuringValues from './MeasuringValues'
import Settings from './Settings'
import LightSwitch from './LightSwitch'
import Navbar from './Navbar'
import { AuthContext } from './AuthContext'

const Tent = ({data, config }) => {
  const {loggedIn} = useContext(AuthContext)

  const checkTimeStatus = (starttime, endtime) => {
    const currentTime = new Date();
    const startTime = new Date();
    const endTime = new Date();
    
    startTime.setHours(...starttime.split(':'));
    endTime.setHours(...endtime.split(':'));

    if (startTime <= endTime) {
      // Zeitbereich innerhalb desselben Tages
      if (currentTime.getTime() >= startTime.getTime() && currentTime.getTime() <= endTime.getTime()) {
        return 'active';
      } else {
        return 'inactive';
      }
    } else {
      // Zeitbereich überspannt Mitternacht
      if (currentTime.getTime() >= startTime.getTime() || currentTime.getTime() <= endTime.getTime()) {
        return 'active';
      } else {
        return 'inactive';
      }
    }
  }
  
  const [dayTime, setDayTime] = useState(config && checkTimeStatus(config[0].light_start, config[0].light_end))


return (
        
    <div className='tent'>
        <Navbar title='Dashboard' />
        <MeasuringValues data={data} config={config} dayTime={dayTime} />
        {/* <Settings data={data} config={config} /> */}

        <div className="lightswitch-holder">
          <LightSwitch showProfileSelector={false} lightName={'Hauptlicht'} config={config} dayTime={dayTime}  />
          <LightSwitch showProfileSelector={false} lightName={'Zweitlicht'} config={config} dayTime={dayTime} />
          <LightSwitch showProfileSelector={true} lightName={'Ventilator'} config={config} dayTime={dayTime} />
          <LightSwitch showProfileSelector={true} lightName={'Abluft'} config={config} dayTime={dayTime} />
        </div>
    </div>
  )
}

export default Tent