import React from "react";
import "./css/LoadingScreen.css";

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="loading-icon"><img src="../../media/canabis_icon.png" alt="" /></div>
    </div>
  );
}

export default LoadingScreen;
