import React from 'react'
import { useState } from 'react';
import './css/PlantForm.css'
import axios from 'axios';
import PlantGrid from './PlantGrid';
import FigmaEmbed from './FigmaEmbed'

const PlantForm = () => {
    const [plantName, setPlantName] = useState('');
    const [plants, setPlants] = useState([])

    const token = '2b104KWVfVB0uTsf3pGBZT7KIO'
    const searchURL = 'https://my-api.plantnet.org/v2/identify/all?'
    const figmaURL = 'https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Ffile%2FKAWCh4wE051QMN42HxJI4L%2FUntitled%3Ftype%3Dwhiteboard%26node-id%3D0%253A1%26t%3DayJenLVZOJSSDfwG-1'
    const figmaURLdesign = 'https%3A%2F%2Fwww.figma.com%2Ffile%2FwUXJb4IoaHBUo6sxmdQdS9%2FUntitled%3Ftype%3Ddesign%26node-id%3D1%253A2%26t%3DUyj2c1wHAGyuociC-1'
    async function fetchApiSearch (key) {
        const formData = new FormData()
        formData.append('searchString', key)
        formData.append('images', 'false')
        formData.append('no-reject', 'false')
        formData.append('lang', 'de')
        formData.append('api-key', token)
        const response = await axios.post(searchURL, formData)
        const slicedData = response.data.data.slice(0, 1)
        setPlants(slicedData)
        console.log(plants)
    }

    function handleSubmit(event) {
        event.preventDefault();
        fetchApiSearch(plantName)
    }

    function handlePlantNameChange(event) {
        setPlantName(event.target.value);
    }



    return (
        <div className='Plantform'>
            <form onSubmit={handleSubmit}>
                <label htmlFor="plant-name">Suche:</label>
                <input type="text" id="plant-name" name="plant-name" value={plantName} onChange={handlePlantNameChange} required />
                <button type="submit">Suchen</button>
                <PlantGrid plants={plants} />
            </form>
            <FigmaEmbed url={figmaURL} />
            {/* <FigmaEmbed url={figmaURLdesign} /> */}

        </div>
    )
}

export default PlantForm