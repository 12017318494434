import React from 'react';
import './css/PlantSteckbrief.css';

function PlantSteckbrief({ name, image, notes, id, handleClick, plant}) {
  
  return (
    <div className="plant-steckbrief" onClick={()=>handleClick(plant)}>
      <img src={image} alt={name} />
      <h2>{name}</h2>
      <p>{notes}</p>
    </div>
  );
}

export default PlantSteckbrief;
