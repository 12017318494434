import React from 'react'
import Navbar from './Navbar'
import './css/Plants.css'
import PlantForm from './PlantForm'
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';


const Plants = () => {

  
  return (
    <div className='plants'>
        <Navbar title='Pflanzen'/>
        <div className="menue">
          <div className="menue-search">
            <PlantForm></PlantForm>
          </div>
        </div>
        
    </div>
  )
}

export default Plants