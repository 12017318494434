import React, { useContext } from 'react'
import Login from './Login'
import './css/Landingpage.css'
import dashboardImg from '../media/Dashboard.png'
import plantsImg from '../media/room.png'
import loginImg from '../media/login.png'
import { AuthContext } from './AuthContext'

const Landingpage = () => {

  const {loggedIn, login, logout } = useContext(AuthContext)
    
  return (
    <div className='landingpage'>
        <div className="linkcontainer">
		      {!loggedIn && 
            <div className="link" style={{backgroundImage: `url(${loginImg})`}} onClick={()=> window.location.href = "/login"}>
              <h2>Login</h2>
            </div>
          }
         
		      <div className="link" style={{backgroundImage: `url(${dashboardImg})`}} onClick={()=> window.location.href = "/dashboard"}>
            <h2>Dashboard</h2>
          </div>
          
		      <div className="link" style={{backgroundImage: `url(${plantsImg})`}} onClick={()=> window.location.href = "/plants"} >
            <h2>Planzen</h2>
          </div>
        
    
	      </div>

    </div>
  )
}

export default Landingpage