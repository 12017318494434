import React from 'react';

const FigmaEmbed = ({ url }) => {
  return (
    <div>
      <iframe
        style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
        width="1600"
        height="900"
        src={url}
        allowFullScreen
        title="Figma Embed"
      ></iframe>
    </div>
  );
};

export default FigmaEmbed;
