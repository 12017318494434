import React, { useContext, useEffect, useState } from 'react';
import './css/MeasuringValues.css';
import Settings from './Settings';
import {BsDroplet} from 'react-icons/bs'
import {FaWind} from 'react-icons/fa'
import {MdCo2} from 'react-icons/md'
import { AuthContext } from './AuthContext';

const MeasuringValues = ({ data, config, dayTime}) => {

  const { loggedIn } = useContext(AuthContext)
  


  useEffect(()=> {
  }, [])
  
  
  
  return (
    <div className={`measuring-values ${dayTime}`}>
      <h2 className="measuring-values-heading">Live</h2>
      <div className="measuring-values-grid">
        <div className="measuring-value-item ph" >
        <div className="measurting-value-box">
          <div className="measuring-value-item-label"><BsDroplet></BsDroplet> pH</div>
        </div>

          <div className="measuring-value-item-value">{data[0].ph}</div>
        </div>
        <div className="measuring-value-item ec">
          <div className="measuring-value-item-label"><BsDroplet></BsDroplet> EC</div>
          <div className="measuring-value-item-value">{data[0].ec}</div>
        </div>
        <div className="measuring-value-item wtemp">
          <div className="measuring-value-item-label"><BsDroplet></BsDroplet> Temperatur</div>
          <div className="measuring-value-item-value">{data[0].wtemp} <span className='unit'>°C</span></div>
        </div>
        <div className="measuring-value-item atemp">
          <div className="measuring-value-item-label"><FaWind></FaWind> Temperatur</div>
          <div className="measuring-value-item-value">{data[0].atemp} <span className='unit'>°C</span></div>
        </div>
        <div className="measuring-value-item rhum">
          <div className="measuring-value-item-label"><FaWind></FaWind> Feuchtigkeit</div>
          <div className="measuring-value-item-value">{data[0].rhum} <span className='unit'>%</span></div>
        </div>
        <div className="measuring-value-item co2">
          <div className="measuring-value-item-label"><FaWind></FaWind> CO²</div>
          <div className="measuring-value-item-value">{data[0].airco2} <span className='unit'>ppm</span></div>
        </div>
      </div>
      <div className="measuring-values-settings">
        <Settings data={data} config={config} loggedIn={loggedIn} />
      </div>
    </div>
  );
};

export default MeasuringValues;
