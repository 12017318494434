import React, { useContext, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import { AuthContext } from './AuthContext';
import './css/Login.css'

const Login = () => {
  const { loggedIn, login, logout } = useContext(AuthContext)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate()
  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLoginClick = () => {
    // Hier kannst du die Anmeldeinformationen überprüfen und den Anmeldestatus verwalten
    // Zum Beispiel, überprüfe die Anmeldeinformationen mit einer API oder einem Backend-Service

    // Wenn die Anmeldeinformationen korrekt sind, rufe die onLogin-Funktion auf
    if (username === 'admin' && password === 'password') {
        setLoginSuccess(true); // Setze den Login-Erfolg auf true
        login()
        setUsername('')
        setPassword('')
        setLoginError('')

    } else {
        // Wenn die Anmeldeinformationen nicht korrekt sind, zeige eine Fehlermeldung an oder nimm andere geeignete Maßnahmen
        setLoginError('Falsche Anmeldeinformationen');
        setTimeout(() => {
            setLoginError('')
        }, 3000);
    }
  };

  return (
    <div className='login'>
        {loggedIn ? navigate('/dashboard'): 
        <>
            <h2>Login</h2>
            <form>
                <div>
                    <label>Username:</label>
                    <input type="text" value={username} onChange={handleUsernameChange} />
                </div>
                <div>
                    <label>Password:</label>
                    <input type="password" value={password} onChange={handlePasswordChange} />
                </div>
                <button type="button" onClick={handleLoginClick}>Login</button>
                {loginSuccess && <p>Login erfolgreich. Du wirst weitergeleitet...</p>}
                <p>{loginError}</p>
            </form>
        </>}

      
    </div>
  );
};


export default Login;
