import React, { useEffect, useState } from 'react';
import PlantSteckbrief from './PlantSteckbrief';
import './css/PlantGrid.css';
import axios from 'axios';


function PlantGrid({plants}) {
  const [species, setSpecies] = useState(null)
  const [showGrid, setShowGrid] = useState(true)
  const [plantName, setPlantName] = useState('')
  const [loading, setLoading] = useState(true);
  const [showPictures, setShowPictures] = useState(false)
  const [showSearchResults] = useState(true)


  async function fetchSpecies(id) {
    const formData = new FormData()
    formData.append('id', id)
    const response = await axios.post('https://restapi.gralla-tec.de/species/', formData)
    setPlantName(response.data.data.common_name)
    setSpecies(response.data.data)
    console.log(response.data.data)
  }

  const handleShowPictures = () => {
    console.log('changed')
    showPictures ? setShowPictures(false) : setShowPictures(true)
  }
  
  const handleClick = (plant) => {
    if (typeof plant.id !== 'undefined') {
      fetchSpecies(plant.id)
    } 
    
    showGrid? setShowGrid(false) : setShowGrid(true)
  }

  const handlePlusClick = () => {

  }

  

  useEffect(()=> {}, [plants, species, showPictures])

  return (
    <div className="plant-grid">

      {showGrid? plants.map((plant) => (
        <PlantSteckbrief key={plant.id} plant={plant} id={plant.id} name={plant.scientific_name} image={plant.image_url} handleClick={handleClick} />
      )): showSearchResults && species?
        <div className="plant-details" >
          <div className="plant-desc-container">
            <div>
              <h1>Information</h1>
              <p>Common Name: {plantName}</p>
              <p>Scientific Name: {species.scientific_name}</p>
              <p>Year: {species.year}</p>
              <p>Bibliography: {species.bibliography}</p>
              <p>Author: {species.author}</p>
              <p>Family: {species.family}</p>
              <p>Observations: {species.observations}</p>
              <p>Vegetable: {species.vegetable}</p>
              <div class="plus-button">
                <a href="/plants/add">+</a>
              </div>
              <button onClick={handleShowPictures}>Show more</button>
              <button onClick={() => handleClick('return')}>Return</button>
            </div>
            <div>
              <img src={species.image_url} alt="" />
            </div>
          </div>
          {showPictures ? <div className="plant-img-container">
          
          {
            species.images.bark ? 
            <>
              <div className="plant-img">
                <img src={species.images.bark[0].image_url} alt="" />
              </div>
            </> :
            <></>
          }       
          {   
            species.images.flower ? 
            <>
              <div className="plant-img">
                <img src={species.images.flower[0].image_url} alt="" />
              </div>
            </> :
            <></>
          }
          {   
            species.images.fruit ? 
            <>
              <div className="plant-img">
                <img src={species.images.fruit[0].image_url} alt="" />
              </div>
            </> :
            <></>
          }
          {   
            species.images.leaf ? 
            <>
              <div className="plant-img">
                <img src={species.images.leaf[0].image_url} alt="" />
              </div>
            </> :
            <></>
          }
          </div> : <></>}
      </div> : <></>
      }
    </div>
  );
}

export default PlantGrid;
