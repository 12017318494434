import React, { useContext, useState } from 'react'
import './css/Navbar.css'
import {FaBars, FaPlus, FaMinus} from 'react-icons/fa'
import { AuthContext } from './AuthContext'

const Navbar = ({title}) => {

  const [dropDown, setDropDown] = useState(false)
  const { loggedIn, logout } = useContext(AuthContext)

  const barsOnClick = () => {
    if (dropDown) {
      setDropDown(false)
    } else setDropDown(true)
  }
  
  return (
    <div className={`navbar .${title}`}>
        <div className="navbar-sec1">
          <h1>{title}</h1>
        </div>
        {!dropDown ? 
          <>
            <div className="navbar-sec2-icon" onClick={barsOnClick}>
              <FaBars></FaBars>
            </div>
          </> 
          : 
          <>
            <div className="navbar-sec2">
              <FaMinus onClick={barsOnClick}></FaMinus>
              <ul className="navbar__navlist">
                {!loggedIn && <li className="navbar__navlist-item"><a href="/login" className="navbar__navlist-itemlink">Login</a></li>}
                <li className="navbar__navlist-item"><a href="/" className="navbar__navlist-itemlink">Start</a></li>
                <li className="navbar__navlist-item"><a href="/dashboard" className="navbar__navlist-itemlink">Dashboard</a></li>
                <li className="navbar__navlist-item"><a href="/plants" className="navbar__navlist-itemlink">Planzen</a></li>
                {loggedIn && <li className="navbar__navlist-item"><a href="/" className="navbar__navlist-itemlink" onClick={logout} >Logout</a></li>}
              </ul>
            </div>
          </>
        }

    </div>
  )
}

export default Navbar