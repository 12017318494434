import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import LoadingScreen from './components/LoadingScreen';
import Landingpage from './components/Landingpage';
import Tent from './components/Tent';
import Plants from './components/Plants';
import PlantForm from './components/PlantForm';
import Login from './components/Login';
import { AuthProvider } from './components/AuthContext';

function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [config, setConfig] = useState(null);



  async function fetchData() {
    try {
      const response1 = await axios.get('https://restapi.gralla-tec.de/tent');
      const data1 = response1.data;
      const obj1 = data1.map((element) => ({
        id: element.id,
        time: element.time,
        ph: element.ph,
        ec: element.ec,
        wtemp: element.wtemp,
        atemp: element.atemp,
        rhum: element.rhum,
        mainlight: element.mainlight,
        secondlight: element.seclight,
        co2: element.co2,
        airout: element.airout,
        airvent: element.airvent,
        airco2: element.air_co2,
      }));
      setData(obj1);

      const response2 = await axios.get('https://restapi.gralla-tec.de/config');
      const data2 = response2.data;
      const obj2 = data2.map((e) => ({
        id: e.id,
        mainlight_set: e.mainlight_set,
        mainlight_set_cont: e.mainlight_set_cont,
        seclight_set: e.seclight_set,
        seclight_set_cont: e.seclight_set_cont,
        airout_set: e.airout_set,
        airout_set_cont: e.airout_set_cont,
        airvent_set: e.airvent_set,
        airvent_set_cont: e.airvent_set_cont,
        light_start: e.light_start,
        light_end: e.light_end,
        light_power: e.light_power,
        light_dimm_time: e.light_dimm_time,
        atemp_max: e.atemp_max,
        atemp_range: e.atemp_range,
        rhum_target: e.rhum_target,
        rhum_range: e.rhum_range,
        airout_timer1: e.airout_timer1,
        airout_timer2: e.airout_timer2,
        airout_timer3: e.airout_timer3,
        airvent_timer1: e.airvent_timer1,
        airvent_timer2: e.airvent_timer2,
        airvent_timer3: e.airvent_timer3,
        airout_start1: e.airout_start1,
        airout_start2: e.airout_start2,
        airout_start3: e.airout_start3,
        airout_end1: e.airout_end1,
        airout_end2: e.airout_end2,
        airout_end3: e.airout_end3,
        airvent_start1: e.airvent_start1,
        airvent_start2: e.airvent_start2,
        airvent_start3: e.airvent_start3,
        airvent_end1: e.airvent_end1,
        airvent_end2: e.airvent_end2,
        airvent_end3: e.airvent_end3,
        seclight_start_time: e.seclight_start_time,
        seclight_end_time: e.seclight_end_time,
      }));
      setConfig(obj2);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      {!loading ? (
        <BrowserRouter>
          <AuthProvider>
            <Routes>
              <Route exact path='/' element={<Landingpage />} />
              <Route path="/dashboard" element={<> <Tent data={data} config={config} /></>} />
              <Route path="/plants" element={<Plants/>} />
              <Route path='/plants/search' element={<PlantForm />} />
              <Route path='/plants/add' element={<PlantForm />} />
              <Route path='/login' element={<Login />} />
            </Routes>
          </AuthProvider>
        </BrowserRouter>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
}

export default App;



